import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import { areYouARobot } from "../utility"
import { UnlockContactInfo, ContactInfo } from "./contact"

export default function LegalNotice({ data }) {
  const [contactInfo, setContactInfo] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  async function unlockContactInfo(e) {
    if (!contactInfo && !loading) {
      setLoading(true)
      const ci = await areYouARobot()
      // too quick :)
      setTimeout(() => {
        setContactInfo(ci)
        setLoading(false)
      }, 1000)
    }
  }

  return (
    <>
      <SEO title="Legal Notice" article={false} noIndex />

      <div className="textTemplateContent">
        <div className="container d-flex justify-content-center">
          <div className="col-12 col-md-8">
            <h1 clasNames="giant">Legal Notice</h1>
            <h3>Imprint & Responsible for content</h3>
            <p>
              Thomas Zipner
              <br />
              Raderberger Str. 154a<br />
              50968 Köln
              <br />
            </p>
            <p>
              {contactInfo ? (
                <ContactInfo contactInfo={contactInfo} />
              ) : (
                <UnlockContactInfo
                  unlockContactInfo={unlockContactInfo}
                  loading={loading}
                />
              )}
            </p>

            <h3>Disclaimer</h3>
            <p>
              The content of this site has been created with the greatest of
              care. I cannot, however, guarantee that the information in it is
              accurate, complete or up-to-date. As a service provider I am
              responsible under Section 6(1) of the German Media Services
              Inter-State Agreement and Section 8(1) of the German Teleservices
              Act for my own content on this site. Service providers are not
              however obliged to monitor third party information transmitted or
              stored on their site by them or to look for circumstances which
              may suggest a violation of the law. This does not affect my
              statutory obligations to remove or block the use of information.
              My liability in such cases shall however commence from the time I
              become aware of an actual violation. On becoming aware of such
              violations I shall remove this content immediately.
            </p>

            <h3>Liability for links</h3>
            <p>
              This website contains links to external third-party websites, over
              the content of which I have no control. I cannot, therefore, make
              any guarantees regarding this third-party content. Responsibility
              for the content of linked sites lies solely with the provider or
              operator of the site concerned. All linked sites were checked for
              possible violations of the law when they were linked to mine. At
              that time I was not aware of any content which may violate the
              law. However, I cannot be expected to monitor the content of
              linked sites on an ongoing basis unless I have reason to suspect a
              violation of the law. On becoming aware of such a violation I
              shall remove the respective link immediately.
            </p>

            <h3>Copyright</h3>
            <p>
              The operator of this site makes every effort to respect the
              copyrights of outside parties and to employ his own material and
              material in the public domain. All content and material on this
              site created by the site operator is governed by German copyright
              law. Downloads and copies of this site may be made for private,
              non-commercial use only. Reproduction, processing, distribution
              and any form of exploitation beyond that permitted by copyright
              law requires the written consent of the author or creator
              concerned. Contributions by third parties are identified as such.
              Should anyone become aware of a possible copyright infringement, I
              kindly request that you inform me of such. Upon becoming aware of
              such a violation I shall remove the respective content
              immediately.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "me_annoyed.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 699) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
